import styles from "./MaxWidth.module.css";
import cx from "classnames";

export type SizeProps = {
  size?: "sm" | "md" | "lg";
};

export type MaxWidthProps = {
  children: any;
} & SizeProps;

export const MaxWidth = ({ children, size = "lg" }: MaxWidthProps) => {
  return <div className={cx(styles.container, styles[size])}>{children}</div>;
};
