import Link from "next/link";
import { ReactNode } from "react";
import { createInternalHref } from "../helpers/createInternalHref";
import { Icon } from "./Icon";

export const LinkWrapper = ({
  url,
  children,
  className,
  onClick,
  addIcon = false,
}: {
  url: string;
  children: ReactNode;
  className?: string;
  onClick?: any;
  addIcon?: boolean;
}) => {
  const isExternal = /^(http|https|mailto|tel):/.test(url);
  if (isExternal) {
    return (
      <a
        href={url}
        className={className}
        onClick={onClick}
        target={isExternal ? "_blank" : "_self"}
        rel={isExternal ? "noreferrer" : undefined}
      >
        {children}
        {addIcon && <Icon icon="external" width={12} />}
      </a>
    );
  }
  if (url && url.match(/^\/.*/)) url = url.substring(1);
  if (!url) url = "#";
  url = createInternalHref({ slug: url });
  return (
    <Link href={url} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};
