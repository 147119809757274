import image from "next/image";
import React from "react";
import { Dump } from "../components/Dump";
import { Marquee } from "../components/Marquee";
import { MaxWidth } from "../layouts/MaxWidth";
import styles from "./FooterPartners.module.css";

export type FooterPartnersProps = {
  title: string;
  text: any;
  partners: any;
};

export const FooterPartners = ({
  title,
  text,
  partners,
}: FooterPartnersProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MaxWidth size="sm">
          <h3 className={styles.title}>{title}</h3>
          {text && <p className={styles.text}>{text}</p>}
        </MaxWidth>
      </div>
      <Marquee>
        {partners.map((item: any) => (
          <React.Fragment key={item.name}>
            {item.logo && (
              <img
                key={item.name}
                className={styles.logo}
                src={item.logo}
                alt={item.name}
              />
            )}
          </React.Fragment>
        ))}
      </Marquee>
    </div>
  );
};
