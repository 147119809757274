import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import cx from "classnames";
import groq from "groq";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { Icon } from "../components/Icon";
import { Logo } from "../components/Logo";
import { LinkWrapper } from "../components/linkWrapper";
import { createInternalHref } from "../helpers/createInternalHref";
import { SchemaType } from "../sanity/types";
import { NavType } from "../types";
import styles from "./Nav.module.css";

export const navItemQuery = groq`
label,
"slug": item -> { "slug": slug.current }.slug,
"type": item -> { _type }._type,
"path": item -> { "slug": slug.current, parent-> }{ "path": coalesce(@.parent.slug.current + "/" + @.slug, @.slug) }.path
`;

export const navQuery = groq`
  "nav": *[_id == "navigation"] { 
    items[] {
      ${navItemQuery},
      subitems[] {
        ${navItemQuery}
      }
    }
   }[0].items
`;

/**
 * Nav item
 */

export type NavItemType = {
  label: string;
  slug: string;
  path?: string;
  type: SchemaType;
  subitems?: NavItemType[];
  float?: boolean;
};

export const NavItem = ({
  label,
  slug,
  path,
  type,
  float,
  subitems = [],
}: NavItemType) => {
  const url =
    slug && type
      ? createInternalHref({ slug, path, type })
      : "https://bigfive.maakjestap.nl/";
  const [open, setOpen] = useState(false);
  const hasSubs = subitems?.length > 0;

  const toggleOpen = () => {
    if (!float) return;
    return setOpen(!open);
  };

  return (
    <div className={cx(styles.navItem, open && styles.navItemHasSubnavOpen)}>
      {!hasSubs && (
        <LinkWrapper
          url={url}
          className={styles.navItemLink}
          onClick={clearAllBodyScrollLocks}
        >
          {label}
        </LinkWrapper>
      )}
      {hasSubs && (
        <span className={styles.navItemLink} onClick={toggleOpen}>
          {label}
          <Icon icon={float ? "chevron" : "caret"} className={styles.caret} />
        </span>
      )}

      {hasSubs && (
        <>
          <div className={cx(styles.subNav, { [styles.subNavOpen]: open })}>
            {subitems.map((item: NavItemType) => (
              <NavItem key={item.label} {...item} float={float} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

/**
 * nav section
 */

export type NavProps = {
  items: NavType;
};

export const Nav = ({ items }: NavProps) => {
  const [float, setFloat] = useState(false);
  const router = useRouter();

  // on route change close float
  useEffect(() => {
    router.events.on("routeChangeStart", closeFloat);
    return () => router.events.off("routeChangeStart", closeFloat);
  }, [router.events]);

  const toggleFloat = () => {
    setFloat(!float);
    disableBodyScroll(document.querySelector("#nav") as HTMLElement);
  };

  const closeFloat = () => {
    setFloat(false);
    clearAllBodyScrollLocks();
  };

  useEffect(() => {
    window.addEventListener("resize", closeFloat);
    return () => window.removeEventListener("resize", closeFloat);
  });

  return (
    <div className={cx(styles.container)}>
      <div
        className={cx(styles.backdrop, { [styles.fixDrop]: float })}
        onClick={closeFloat}
      ></div>
      <LinkWrapper url="homepage" className={styles.mainLogo}>
        <Logo className={styles.logo} />
      </LinkWrapper>
      <nav id="nav" className={cx(styles.nav, { [styles.float]: float })}>
        <LinkWrapper url="homepage" className={styles.floatLogo}>
          <Logo className={styles.logo} />
        </LinkWrapper>
        <button className={styles.closeFloat} onClick={closeFloat}>
          <Icon icon="close" className={styles.closeIcon} />
        </button>
        {items &&
          items.map((item) => (
            <NavItem key={item.label} float={float} {...item} />
          ))}
        {float && (
          <Button
            variation="outline"
            className={styles.button}
            url="stappenplan"
          >
            Maak jouw stappenplan
          </Button>
        )}
      </nav>
      <Button variation="outline" className={styles.button} url="stappenplan">
        Maak jouw stappenplan
      </Button>
      <button className={styles.toggleNav} onClick={toggleFloat}>
        <Icon icon="navToggle" width={24} height={24} />
      </button>
    </div>
  );
};
