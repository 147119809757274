/* eslint-disable react-hooks/rules-of-hooks */
// import { Flex } from "../layouts/Flex";
import { Icons } from "./Icons";
// import styles from "./Icon.module.css";

export type IconsType = {
  [key: string]: JSX.Element;
};

export type IconType = {
  icon: keyof typeof Icons;
  className?: string;
  rotate?: 0 | 90 | 180 | 270;
  width?: number;
  height?: number;
  title?: string;
  color?: string;
};

export const iconNames = Object.keys(Icons);

export const Icon = ({
  icon,
  className,
  width = 24,
  height = 24,
  title,
  color,
  rotate = 0,
  ...rest
}: IconType) => {
  if (!Icons[icon]) return null;
  const Element = () => Icons[icon];
  const transform = rotate ? "rotate(" + rotate + "deg)" : "rotate(0deg)";

  return (
    <svg
      viewBox="0 0 24 24"
      width={width + "px"}
      height={height + "px"}
      className={className}
      style={{ flexShrink: 0 }}
      {...rest}
    >
      {title && <title>{title}</title>}
      <g
        transform-origin="50% 50%"
        fill={color}
        style={{ transform: transform, transition: "transform .1s" }}
      >
        <Element />
      </g>
    </svg>
  );
};
