// internal link
export const link = {
  name: "link",
  title: "Interne link",
  type: "reference",
  to: [
    { type: "page" },
    { type: "landing.coursefinder" },
    { type: "landing.expertises" },
    { type: "landing.homepage" },
    { type: "landing.partners" },
    { type: "landing.stories" },
    { type: "landing.wizard" },
    { type: "expertise" },
  ],
};

// internal or external link
export const linkUrlGroq = `
select(
  url.type == 'link' && url.link->_type == 'expertise' => 'vakgebieden/' + url.link->slug.current,
  url.type == 'link' && url.link->_type == 'story' => 'verhalen/' + url.link->slug.current,
  url.type == 'link' && url.link->parent != empty => url.link->parent->slug.current + '/' + url.link->slug.current,
  url.type == 'link' => url.link->slug.current,
  url.type == 'url' => url.url,
  url.type == 'email' => 'mailto:'+url.email,
  url.type == 'phone' => 'tel:'+url.phone,
)
`;
// href version (tech debt, don't re-use)
export const linkUrlHrefGroq = `
select(
  href.type == 'link' && href.link->_type == 'expertise' => 'vakgebieden/' + href.link->slug.current,
  href.type == 'link' && href.link->_type == 'story' => 'verhalen/' + href.link->slug.current,
  href.type == 'link' && href.link->parent != empty => href.link->parent->slug.current + '/' + href.link->slug.current,
  href.type == 'link' => href.link->slug.current,
  href.type == 'url' => href.url,
  url.type == 'email' => 'mailto:'+href.url,
  url.type == 'phone' => 'tel:'+href.url,
)
`;

export const linkUrl = {
  name: "linkUrl",
  type: "object",
  title: "URL/Link",
  fields: [
    {
      name: "type",
      type: "string",
      options: {
        default: "link",
        list: [
          { title: "External url", value: "url" },
          { title: "Internal link", value: "link" },
          { title: "E-Mail", value: "email" },
          { title: "Phone", value: "phone" },
        ],
        layout: "radio",
        direction: "horizontal",
      },
    },
    {
      name: "url",
      type: "url",
      title: "URL",
      placeholder: "https://example.com",
      hidden: ({ parent }: any) => parent?.type !== "url",
    },
    {
      name: "link",
      type: "link",
      hidden: ({ parent }: any) => parent?.type !== "link",
    },
    {
      name: "email",
      type: "string",
      title: "E-mail address",
      placeholder: "info@example.com",
      hidden: ({ parent }: any) => parent?.type !== "email",
      validation: (Rule: any) => Rule.email(),
    },
    {
      name: "phone",
      type: "string",
      title: "Phone number",
      placeholder: "+31-1234567890",
      hidden: ({ parent }: any) => parent?.type !== "phone",
      validation: (Rule: any) => Rule.regex(/^[\+\d\-\s\(\)]*$/),
    },
  ],
};

export const navItem = {
  name: "navItem",
  type: "object",
  title: "Navigation item",
  fields: [
    {
      name: "label",
      title: "Label",
      type: "string",
    },
    {
      name: "url",
      title: "Link",
      type: "linkUrl",
    },
  ],
};
