import React from "react";
import styles from "./PageTitle.module.css";
import cx from "classnames";
import { MaxWidth } from "../layouts/MaxWidth";
import { Deco } from "../components/Deco";

export type PageTitleProps = {
  title: string;
  eyebrow?: string;
};

export const PageTitle = ({ title, eyebrow }: PageTitleProps) => {
  return (
    <div className={cx(styles.pageTitle)}>
      <MaxWidth size="sm">
        <h1 className={cx(styles.title, { [styles.frowning]: eyebrow })}>
          {eyebrow && (
            <small className={styles.eyebrow}>
              <Deco deco="underline-yellow">{eyebrow}</Deco>
            </small>
          )}
          {title}
        </h1>
        {!eyebrow && (
          <svg
            className={styles.titleScribble}
            fill="none"
            viewBox="0 0 240 12"
          >
            <path
              d="M240 9.84c-.4 1.82-1.82 2.15-3.3 1.84-22.8-4.8-46.17-3.34-69.32-3.99-43.66-1.21-87.27.95-130.89 2.12-10.07.28-20.11 1.35-30.16 1.95C4 11.9.49 12.7.02 10.32c-.29-1.39 3.5-1.9 5.66-2.17 17.7-2.25 35.47-4.13 53.3-5.52C106.46-1.04 154.03-.04 201.61.76c5.54.1 11.09-.5 16.64-.67 2.42-.08 5.31-.53 6.02 2.22 1.19 4.58 5.36 5.2 10.02 5.46 2.22.12 4.5.4 5.71 2.07Z"
              fill="#FDC133"
            />
          </svg>
        )}
      </MaxWidth>
    </div>
  );
};
