export const createInternalHref = ({
  slug,
  type,
  path,
}: {
  slug: string;
  type?: string;
  path?: string;
}) => {
  // find type
  if (!type) type = typeBySlug(slug);

  // homepage
  if (type === "landing.homepage") return `/`;

  // landingpages
  if (type.match(/^landing\./)) return `/${slug}`;

  // detail pages
  if (type === "expertise") {
    slug = slug.replace("vakgebieden/", "");
    return `/vakgebieden/${slug}`;
  }
  if (type === "story") {
    slug = slug.replace("/verhalen/", "");
    slug = slug.replace("verhalen/", "");
    return `/verhalen/${slug}`;
  }

  // slug pages
  if (type === "page" && path) return `/${path}`;
  if (type === "page") return `/${slug}`;
  return `/${type}/${slug}`;
};

export const typeBySlug = (slug: string) => {
  if (slug === "") return "landing.homepage";
  if (slug === "homepage") return "landing.homepage";
  if (slug === "/") return "landing.homepage";
  if (slug === "vakgebieden") return "landing.expertises";
  if (slug === "verhalen") return "landing.stories";
  if (slug === "opleidingen") return "landing.coursefinder";
  if (slug === "partners") return "landing.partners";
  if (slug === "stappenplan") return "landing.wizard";
  if (slug.match(/vakgebieden\/.*/)) return "expertise";
  if (slug.match(/verhalen\/.*/)) return "story";
  return "page";
};
