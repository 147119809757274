import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { Button } from "../components/Button";
import { CookieBar } from "../components/CookieBar";
import { ThemeProvider } from "../context/ThemeContext";
import { useAppContext } from "../helpers/useAppContext";
import { MaxWidth } from "../layouts/MaxWidth";
import { Footer } from "../sections/Footer";
import { Nav } from "../sections/Nav";
import { PageTitle } from "../sections/PageTitle";
import { FooterType, NavType, themeColor } from "../types";
import { ColorShift, ColorShiftTrigger } from "./ColorShift";
import styles from "./Page.module.css";

export type PageProps = PageHeadProps & {
  children?: React.ReactNode;
  nav: NavType;
  footer?: FooterType;
  notFound?: boolean;
  navColor?: themeColor;
  preview?: boolean;
};

export const Page = ({
  title,
  description,
  image,
  nav,
  footer,
  notFound = false,
  children,
  navColor = "white",
  preview = false,
}: PageProps) => {
  const router = useRouter();
  const { cookies } = useAppContext();
  const { accepted, setAccepted, setAdvertising, setAnalytics } = cookies;

  const onMeh = () => {
    setAccepted(false);
    setAdvertising(false);
    // setAnalytics(false);
  };

  if (router.isFallback) {
    return (
      <div>
        <PageHead title={title} description={description} image={image} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <PageSignature />
          <PageLoader />
        </div>
      </div>
    );
  }

  if (notFound) {
    return (
      <div>
        <PageHead title={title} description={description} image={image} />
        <Nav items={nav} />
        <PageTitle title="Pagina niet gevonden" />
        <MaxWidth size="sm">
          <p>Helaas, de aangevraagde pagina werd niet gevonden.</p>
        </MaxWidth>
        <Footer {...footer} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {preview && (
        <div className={styles.preview}>
          This page is in preview mode
          <Link href="/api/exit-preview" className={styles.exitPreview}>
            Exit preview mode
          </Link>
        </div>
      )}

      <PageHead title={title} description={description} image={image} />
      <PageSignature />

      <ThemeProvider>
        <ColorShift>
          <ColorShiftTrigger color={navColor} style={{ zIndex: 10 }}>
            {nav && <Nav items={nav} />}
          </ColorShiftTrigger>
          {children}
          <Footer {...footer} />
        </ColorShift>
      </ThemeProvider>
      {!accepted && (
        <CookieBar
          setAccepted={setAccepted}
          setAdvertising={setAdvertising}
          setAnalytics={setAnalytics}
        />
      )}
      {preview && <Button onClick={onMeh}>Reset cookies</Button>}
    </div>
  );
};

interface PageHeadProps {
  title?: string;
  description?: string;
  image?: string;
}

const PageHead = ({ title, description, image }: PageHeadProps) => {
  let theTitle = title?.includes("MaakJeStap.nl")
    ? title
    : [title, "Maak je Stap"].filter(Boolean).join(" - ");
  return (
    <Head>
      <title>{theTitle}</title>
      {title && <meta name="title" content={title} />}
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:type" content="website" />
      {image && <meta property="og:image" content={image} />}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content={`@maakjestap`} />
      {title && <meta property="twitter:title" content={title} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      <link
        rel="preload"
        href={"/images/wizard_hero.svg"}
        as="image"
        key={"wizard_hero"}
      />
      <meta
        name="google-site-verification"
        content="w17Hg9aR-GR3OWIzb07hf2aqzfyS2hBlecjsaitktdM"
      />
      {image && <meta property="twitter:image" content={image} />}
    </Head>
  );
};

const PageSignature = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
        <!--
          Design and development:   Eight Media, User Experience Design Agency
          Contact:                  hello@eight.nl
        -->
      `,
    }}
  />
);

const PageLoader = () => <div className={styles.loading}>loading</div>;

const PageError = () => <div className={styles.error}>404</div>;
