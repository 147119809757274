import React, { useState, createContext, useContext } from "react";
import { themeColor } from "../types";

type Dispatch = (color: themeColor) => void;
const ThemeContext = createContext<
  | {
      theme: themeColor;
      setTheme: Dispatch;
    }
  | undefined
>(undefined);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<themeColor>("white");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
