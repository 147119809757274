import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { useTheme } from "../context/ThemeContext";
import { useInView } from "../hooks/useInView";
import { useScrollDirection } from "../hooks/useScrollDirection";
import { themeColor } from "../types";
import styles from "./ColorShift.module.css";

export type ColorShiftProps = {
  color: themeColor;
  children?: ReactNode;
  style?: CSSProperties;
};

export const ColorShiftTrigger = ({
  color,
  children,
  style,
}: ColorShiftProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const elementTopRef = useRef<HTMLDivElement>(null);
  const elementBottomRef = useRef<HTMLDivElement>(null);

  const { setTheme } = useTheme();
  const scrollDirection = useScrollDirection();

  const topInView = useInView({
    elementRef: elementTopRef,
    threshold: 1,
  });
  const bottomInView = useInView({
    elementRef: elementBottomRef,
    threshold: 1,
  });

  useEffect(() => {
    if (topInView && (scrollDirection === "down" || !scrollDirection)) {
      setTheme(color);
    }
  }, [topInView, setTheme, scrollDirection]);

  useEffect(() => {
    if (bottomInView && (scrollDirection === "up" || !scrollDirection)) {
      setTheme(color);
    }
  }, [bottomInView, setTheme, scrollDirection]);

  return (
    <div ref={elementRef} className={styles.container} style={style}>
      <div ref={elementTopRef} className={styles.top} />
      {children}
      <div ref={elementBottomRef} className={styles.bottom} />
    </div>
  );
};

export const ColorShift = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme();
  const [style, setStyle] = useState<CSSProperties>();

  useEffect(() => {
    setStyle({
      backgroundColor: `var(--color-${theme})`,
      transition: "background-color .75s",
    });
  }, [theme]);

  return (
    <div id="colorShift" style={style}>
      {children}
    </div>
  );
};
