import React, { ReactNode } from "react";
import styles from "./Grid.module.css";
import { MaxWidth } from "./MaxWidth";
import cx from "classnames";

export type GridProps = {
  children: any;
  layout?: "none" | "split-65" | "split";
  className?: string;
};

export const Grid = ({ children, layout = "none", className }: GridProps) => {
  return (
    <div className={cx(styles.grid, styles[layout], className)}>{children}</div>
  );
};

export const GridContainer = ({ children, layout }: GridProps) => {
  return (
    <MaxWidth>
      <Grid layout={layout}>{children}</Grid>
    </MaxWidth>
  );
};
