import { useRouter } from "next/router";
import styles from "./CookieBar.module.css";
import React from "react";
import { MaxWidth } from "../layouts/MaxWidth";
import { Button } from "./Button";

export const CookieBar = ({
  setAccepted,
  setAdvertising,
  setAnalytics,
}: {
  setAccepted: any;
  setAdvertising: any;
  setAnalytics: any;
}) => {
  const router = useRouter();

  const onOkay = () => {
    setAdvertising(1);
    setAnalytics(1);
    setAccepted(1);
    window.location.reload();
  };

  const onChangeClick = () => {
    router.push(`/${router?.locale}/cookies?redirect=1`);
    setAccepted(1);
  };

  return (
    <div className={styles.container}>
      <MaxWidth>
        <h3 className={styles.title}>Cookies</h3>
        <p className={styles.text}>
          MaakJeStap maakt gebruik van cookies voor zover deze noodzakelijk zijn
          voor een goede werking van de website en om de kwaliteit of
          effectiviteit van de website te verbeteren.
        </p>
        <div className={styles.buttonContainer}>
          <Button onClick={onOkay}>Akkoord</Button>
          <Button onClick={onChangeClick} variation="secondary-diap">
            Cookies aanpassen
          </Button>
        </div>
      </MaxWidth>
    </div>
  );
};
