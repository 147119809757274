import groq from "groq";
import Link from "next/link";
import { Icon } from "../components/Icon";
import { LinkWrapper } from "../components/linkWrapper";
import { LogoWhite } from "../components/Logo";
import { Grid } from "../layouts/Grid";
import { MaxWidth } from "../layouts/MaxWidth";
import { linkUrlGroq } from "../sanity/schemas/link";
import styles from "./Footer.module.css";
import { FooterPartners } from "./FooterPartners";
import { footerPartnersQuery } from "./FooterPartners.sanity";

export const footerQuery = groq`
  "footer": *[_id == "footer"] {
    ...,
    "partners": partners{
      ...,
      "logos": ${footerPartnersQuery},
    },
    "meta": meta {
      "items": items[]{
        label,
        "url": ${linkUrlGroq}
      }
    },
    "nav1": nav1 {
      title,
      "items": items[]{
        label,
        "url": ${linkUrlGroq}
      }
    },
    "nav2": nav2 {
      title,
      "items": items[]{
        label,
        "url": ${linkUrlGroq}
      }
    },
    "nav3": nav3 {
      title,
      "items": items[]{
        label,
        "url": ${linkUrlGroq}
      }
    }
   }[0]
`;

export type FooterCol = {
  title?: string;
  items: {
    label: string;
    url: string;
  }[];
};

export type FooterProps = {
  descriptor: {
    title: string;
    subTitle: string;
  };
  partners: any;
  nav1: FooterCol;
  nav2: FooterCol;
  nav3: FooterCol;
  colophon: string;
  meta: FooterCol;
  social: {
    youtube?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
  };
};

let nav = 0;

const SocialLink = ({
  network,
  url,
}: {
  network: "youTube" | "twitter" | "linkedIn" | "instagram";
  url: string;
}) => {
  return (
    <Link href={url} className={styles.socialLink}>
      <Icon
        icon={network}
        className={styles.socialIcon}
        width={24}
        height={24}
        title={network}
      />
    </Link>
  );
};

const NavCol = ({ title, items = [] }: FooterCol) => (
  <div className={styles["nav" + nav++]}>
    <h3 className={styles.listTitle}>{title}</h3>
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item.label} className={styles.listItem}>
          <LinkWrapper url={item.url} className={styles.listLink}>
            {item.label}
          </LinkWrapper>
        </li>
      ))}
    </ul>
  </div>
);

export const Footer = ({
  descriptor,
  nav1,
  nav2,
  nav3,
  colophon,
  meta,
  social,
  partners,
}: FooterProps) => {
  return (
    <footer className={styles.footer}>
      {partners?.logos && (
        <FooterPartners
          title={partners.title}
          text={partners.subTitle}
          partners={partners.logos}
        />
      )}
      <MaxWidth size="lg">
        <Grid className={styles.grid}>
          <div className={styles.logoWrapper}>
            <LogoWhite className={styles.logo} />
            {descriptor?.title && (
              <h2 className={styles.title}>{descriptor?.title}</h2>
            )}
            {descriptor?.subTitle && (
              <p className={styles.subTitle}>{descriptor?.subTitle}</p>
            )}
          </div>
          <NavCol {...nav1} />
          <NavCol {...nav2} />
          <NavCol {...nav3} />
        </Grid>
        <div className={styles.sub}>
          <div className={styles.subnav}>
            <span className={styles.colophon}>{colophon}</span>
            <Link href="/cookies" className={styles.subNavItem}>
              Cookie instellingen
            </Link>
            {meta &&
              meta.items.map((item) => (
                <LinkWrapper
                  key={item.label}
                  url={item.url || "#"}
                  className={styles.subNavItem}
                >
                  {item.label}
                </LinkWrapper>
              ))}
          </div>

          {social && (
            <div className={styles.social}>
              {social.youtube && (
                <SocialLink network="youTube" url={social.youtube} />
              )}
              {social.twitter && (
                <SocialLink network="twitter" url={social.twitter} />
              )}
              {social.linkedin && (
                <SocialLink network="linkedIn" url={social.linkedin} />
              )}
              {social.instagram && (
                <SocialLink network="instagram" url={social.instagram} />
              )}
            </div>
          )}
        </div>
      </MaxWidth>
    </footer>
  );
};
