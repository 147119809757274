import cx from "classnames";
import styles from "./Deco.module.css";

export type DecoType =
  | "top-center-scribble-magenta"
  | "top-center-scribble-yellow"
  | "top-left-sparkle-magenta"
  | "top-left-sparkle-green"
  | "topline-magenta"
  | "underline-yellow"
  | "dot"
  | "dashed-underline"
  | "sparkle-magenta"
  | "arrow-magenta"
  | "crooked-arrow"
  | "left-arrow"
  | "arrow-down";

export type DecoProps = {
  children: any;
  deco?: DecoType;
};

export const Deco = ({ children, deco }: DecoProps) => {
  return (
    <>
      {deco ? (
        <span className={cx(styles.container, styles[deco])}>{children}</span>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
