import cx from "classnames";
import styles from "./Button.module.css";
import React, { MouseEventHandler } from "react";
import { Icon } from "./Icon";
import { LinkWrapper } from "./linkWrapper";

export type ButtonProps = {
  label?: string;
  children?: React.ReactNode;
  url?: string;
  variation?:
    | "primary"
    | "secondary"
    | "primary-diap"
    | "secondary-diap"
    | "outline";
  size?: "sm" | "md";
  icon?: "download" | "external" | "play";
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
};

const InnerButton = ({ icon, children, label }: ButtonProps) => (
  <>
    {icon && (
      <Icon icon={icon} className={cx(styles.icon, styles["icon-" + icon])} />
    )}
    <span className={styles.text}>{children ? children : label}</span>
  </>
);

export const Button = ({
  label = "Click me!",
  url,
  variation = "primary",
  size = "md",
  children,
  disabled = false,
  icon,
  className,
  onClick,
}: ButtonProps) => {
  const classNames = cx(
    className,
    styles.btn,
    styles[size],
    styles[variation],
    {
      [styles["hasIcon-" + icon]]: icon,
    },
    {
      [styles.disabled]: disabled,
    }
  );

  if (disabled) {
    url = undefined;
    onClick = undefined;
  }

  // button with a link makes an anchor element
  if (url) {
    return (
      <LinkWrapper url={url} className={classNames}>
        <InnerButton {...{ icon: icon, children: children, label: label }} />
      </LinkWrapper>
    );
  }

  // button without url is a <button>
  return (
    <button className={classNames} onClick={onClick} disabled={disabled}>
      <InnerButton {...{ icon: icon, children: children, label: label }} />
    </button>
  );
};
