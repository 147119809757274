import { IconsType } from "./Icon";

const Icons: IconsType = {};

Icons.navToggle = (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 4H2v2h20V4Zm0 14H2v2h20v-2ZM2 11h20v2H2v-2Z"
    />
  </>
);

Icons.arrow = (
  <>
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M5.571 11.678a.75.75 0 100 1.5v-1.5zm12.53 1.28a.75.75 0 000-1.06l-4.773-4.772a.75.75 0 00-1.06 1.06l4.242 4.242-4.242 4.243a.75.75 0 101.06 1.061l4.773-4.773zm-12.53.22h12v-1.5h-12v1.5z"
      fill="#262429"
    />
  </>
);

Icons.play = (
  <>
    <circle cx="12" cy="12" r="12" fill="#ffffff" />
    <path d="M9 6.667v10l8-5-8-5z" fill="#262429" />
  </>
);

Icons.close = (
  <>
    <path d="M20 5.4L18.6 4 12 10.6 5.4 4 4 5.4l6.6 6.6L4 18.6 5.4 20l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L20 5.4z" />
  </>
);

Icons.search = (
  <>
    <path
      d="M16.3 14.9c1-1.3 1.7-3 1.7-4.9 0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8c1.8 0 3.5-.6 4.9-1.7l5.7 5.7 1.4-1.4-5.7-5.7zM10 16c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6c0 1.3-.4 2.5-1.1 3.5-.4.5-.9 1-1.4 1.4-1 .7-2.2 1.1-3.5 1.1z"
      fill="currentColor"
    />
  </>
);

Icons.clear = (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM15.59 17L17 15.59L13.41 12L17 8.41L15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17Z"
      fill="currentColor"
    />
  </>
);

Icons.external = (
  <>
    <path
      d="M13.35 0v3h5.55L8.4 13.5l2.1 2.1L21 5.1v5.55h3V0H13.35z"
      fill="currentColor"
    />
    <path d="M18 21H3V6h4.5V3H0v21h21v-7.5h-3V21z" fill="currentColor" />
  </>
);

Icons.chevron = (
  <>
    <path
      d="M1.8 16.2l2.1 2.1 8.4-8.4 8.4 8.4 2.1-2.1L12.3 5.7 1.8 16.2z"
      fill="currentColor"
    />
  </>
);

Icons.caret = (
  <>
    <path d="M19.5 15L12 7.5 4.5 15h15z" fill="currentColor" />
  </>
);

Icons.check = (
  <>
    <path
      d="M8.4 21.45l-6.9-6.9 3-2.85 3.9 3.9L19.5 4.5l3 3L8.4 21.45z"
      fill="currentColor"
    />
  </>
);

Icons.download = (
  <>
    <path
      d="M21 21H0v3h24v-3h-3zM19.5 10.5l-2.1-2.1-3.9 3.9V0h-3v12.3L6.6 8.4l-2.1 2.1L12 18l7.5-7.5z"
      fill="currentColor"
    />
  </>
);

Icons.linkedIn = (
  <>
    <path
      d="M20.571 2H3.424C2.638 2 2 2.647 2 3.442v17.116C2 21.353 2.638 22 3.424 22h17.147c.786 0 1.429-.647 1.429-1.442V3.442C22 2.647 21.357 2 20.571 2zM8.045 19.143H5.08V9.598h2.97v9.545h-.005zM6.563 8.295a1.72 1.72 0 010-3.438c.946 0 1.718.772 1.718 1.719 0 .95-.768 1.719-1.719 1.719zm12.593 10.848h-2.964V14.5c0-1.107-.022-2.531-1.54-2.531-1.545 0-1.781 1.205-1.781 2.45v4.724H9.905V9.598h2.844v1.304h.04c.398-.75 1.366-1.54 2.808-1.54 3 0 3.558 1.977 3.558 4.549v5.232z"
      fill="currentColor"
    />
  </>
);

Icons.twitter = (
  <>
    <path
      d="M19.944 7.987c.013.175.013.35.013.525C19.957 13.85 15.833 20 8.294 20c-2.322 0-4.48-.663-6.294-1.813.33.038.647.05.99.05 1.916 0 3.68-.637 5.089-1.724-1.802-.038-3.313-1.2-3.833-2.8.254.037.508.062.774.062.368 0 .736-.05 1.079-.138-1.878-.375-3.287-2-3.287-3.962v-.05c.546.3 1.18.488 1.853.512a4.02 4.02 0 01-1.827-3.362c0-.75.203-1.438.558-2.038a11.71 11.71 0 008.452 4.225 4.495 4.495 0 01-.102-.924c0-2.226 1.828-4.038 4.1-4.038 1.18 0 2.245.487 2.994 1.275a8.145 8.145 0 002.602-.975 4.038 4.038 0 01-1.802 2.225A8.316 8.316 0 0022 5.9a8.74 8.74 0 01-2.056 2.087z"
      fill="currentColor"
    />
  </>
);

Icons.youTube = (
  <>
    <path
      d="M22.54 6.503c-.253-.985-.998-1.761-1.945-2.024C18.88 4 12 4 12 4s-6.88 0-8.595.479c-.947.263-1.692 1.039-1.945 2.024C1 8.29 1 12.016 1 12.016s0 3.727.46 5.513a2.77 2.77 0 001.945 1.992C5.12 20 12 20 12 20s6.88 0 8.595-.479a2.77 2.77 0 001.945-1.992c.46-1.786.46-5.513.46-5.513s0-3.726-.46-5.513zM10 15.5V9l5.5 3-5.5 3.5z"
      fill="currentColor"
    />
  </>
);

Icons.instagram = (
  <>
    <path
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
      fill="currentColor"
    />
  </>
);

export { Icons };
