import React from "react";
import styles from "./Marquee.module.css";

export type MarqueeProps = {
  children: any;
};

export const Marquee = ({ children }: MarqueeProps) => {
  const animationDuration = children.length * 8;
  return (
    <div className={styles.container}>
      <div
        className={styles.wrapper}
        style={{ animationDuration: animationDuration + "s" }}
      >
        {children}
        {children}
        {children}
        {children}
      </div>
    </div>
  );
};
